<template>
  <div>
    <a-form-model
        ref="searchForm"
        v-bind="layout"
        :model="searchForm"
        class="search-form"
    >
      <a-row>
        <a-col :span="6">
          <a-form-model-item label="媒体号">
            <a-input v-model.trim="searchForm.code" placeholder="请输入"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="主播ID">
            <a-input v-model.trim="searchForm.author_id" placeholder="请输入"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="昵称">
            <a-input v-model.trim="searchForm.nickName" placeholder="请输入"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="room_id">
            <a-input v-model.trim="searchForm.room_id" placeholder="请输入"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="18"></a-col>
        <a-col :span="6">
          <a-form-model-item>
            <a-button @click="resetSearch">重置</a-button>
            <a-button
                style="margin-left: 10px"
                type="primary"
                @click="searchClick"
            >搜索
            </a-button
            >
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-tabs default-active-key="1" @change="changeCallback">
      <a-tab-pane key="1" tab="抖音">
      </a-tab-pane>
      <a-tab-pane key="2" tab="快手">
      </a-tab-pane>
    </a-tabs>
    <!-- 表格 -->
    <a-table
        :columns="columns"
        :data-source="liveTableList"
        :loading="tableLoading"
        :pagination="paginations"
        :rowKey="(record, index) => index"
        bordered
        @change="changeTable"
    >
      <div v-if="defaultActive == 1" slot='douyin'>抖音</div>
      <div v-if="defaultActive == 2" slot='douyin'>快手</div>
      <div slot="ossUrl" slot-scope="text, record">
        <a-button v-if="record.status == 'processing' && record.oss_status !=='Archive'" type='link' @click="checkStatus(record)">加载中</a-button>
        <a-button v-if="record.status == null && record.oss_status !=='Archive' && record.url" type='link' @click="openTips(record)"  :loading='btnLoading&&record.room_id==activeId'>加载视频</a-button>
        <a-button v-if="record.status =='success' && record.url && record.oss_status !=='Archive'" :loading='btnLoading&&record.room_id==activeId' type='link'
                  @click="gotoliveTailor(record)">进入剪辑
        </a-button>
      </div>
    </a-table>
  </div>
</template>

<script>
import api from "@/api/Edit.js";

const columns = [
  {
    title: "主播昵称",
    dataIndex: "nickname",
  },
  {
    title: "媒体号",
    dataIndex: "code",
  },
  {
    title: "主播ID",
    dataIndex: "author_id",
  },
  {
    title: "平台",
    dataIndex: "douyin",
    scopedSlots: {customRender: "douyin"},
    width: 70,
  },
  {
    title: "直播场次",
    dataIndex: "publish_time",
  },
  {
    title: "主题",
    dataIndex: "title",
  },
  {
    title: "room_id",
    dataIndex: "room_id",
  },
  {
    title: "操作",
    dataIndex: "ossUrl",
    scopedSlots: {customRender: "ossUrl"},
    width: 70,
  },
];
export default {
  name: "liveTableList",
  data() {
    return {
      columns,
      searchForm: {
        nickName: "",
        code: "",
        author_id: "",
        room_id: ''
      },
      liveTableList: [],
      tableLoading: false,
      layout: {
        labelCol: {span: 5},
        wrapperCol: {span: 17},
      },
      paginations: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: total => `总共 ${total} 条数据`,
      },
      btnLoading: false,
      activeId: '',
      defaultActive: 1,
    }
  },
  mounted() {
    this.getPayLoad(1)
  },
  methods: {
    changeCallback(key) {
      this.defaultActive = key
      this.resetSearch()
    },
   async getPayLoad(current) {
      let payload = {
        page_no: current,
        page_size: 10,
        nickName: this.searchForm.nickName || "",
        code: this.searchForm.code || "",
        author_id: this.searchForm.author_id || "",
        room_id: this.searchForm.room_id || '',
      };
      this.tableLoading = true;
      await  api[this.defaultActive == 1 ? "getdyTailorTabelList" : "getksailorTabelList"](payload).then((res) => {
        this.tableLoading = false;
        if (res.code == 200) {
          this.liveTableList = res.data.list || []
          this.paginations.total = parseInt(res.data.total)
          this.paginations.current = res.data.page_num
        }
      });
    },
    searchClick() {
      this.getPayLoad(1);
    },
    resetSearch() {
      this.searchForm = {
        nickName: "",
        code: "",
        author_id: "",
        room_id: '',
      }
      this.getPayLoad(1);
    },
    changeTable(pagination) {
      this.getPayLoad(pagination.current);
    },
    // 跳转
    gotoliveTailor(record) {
      let params = {
        roomId: record.room_id,
        roomTitle: record.title,
        //  roomId:'6995013476569582372',
      }
      let data = {
        roomId: record.room_id,
      }
      this.btnLoading = true;
      this.activeId = record.room_id
      api.getLiveAddOss(params).then(res => {
        if(res.code==200){
          api.getLiveAddStatus(data).then(res=>{
            if(res.code == 200 && res.data.process == 'success'){
              const {href} = this.$router.resolve({
                name: 'console:edit',
                query: {
                  roomId: record.room_id,
                  projectId: res.data.ice_project_id,
                  dealerName: record.dealer_name
                }
              })
              window.open(href, '_blank')
            }else{
              this.$message.warning('剪辑工程正在生成中，请稍后重试！')
            }
          }).finally(() => this.btnLoading = false)
        }
        if (res.code == '1701001013') {
          this.$message.error(res.message)
        }
        this.btnLoading = false;
        const {href} = this.$router.resolve({
          path: '/console/edit',
          query: {
            roomId: record.room_id,
            projectId: res.data.projectId,
            dealerName: record.dealer_name
          }
        })
        window.open(href, '_blank')

      }).finally(() => this.btnLoading = false)
    },
    // 提示加载
    openTips(record){
      let payload = {
       roomId: record.room_id,
       roomTitle:record.title
      }
      this.btnLoading = true;
      this.activeId = record.room_id
      api.getLiveAddOss(payload).then(res=>{
        if(res.code==200){
          this.getPayLoad(this.paginations.current)
          this.$notification.open({
            message: '温馨提示',
            description:
              '视频正在加载中，请稍等片刻，加载完成后才可进入剪辑。',
            icon: <a-icon type="smile" style="color: #108ee9" />,
          });
        }
      }).catch((res)=>{
        this.$message.error(res.message)
      }).finally(()=>{
          this.btnLoading = false
      })
    },
    // 检查提示状态
   async checkStatus(value){
    await  this.getPayLoad(this.paginations.current)
      this.liveTableList.forEach(item=>{
        if(item.room_id == value.room_id){
          if(item.status == 'processing'){
            this.$notification.open({
              message: '温馨提示',
              description:
                '视频正在加载中，请稍等片刻，加载完成后才可进入剪辑。',
              icon: <a-icon type="smile" style="color: #108ee9" />,
            });
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
